import { RiShakeHandsLine, RiShakeHandsFill } from '@remixicon/react'
import { PERMISSION } from '../../constants'
import { createItem } from '../../help'
import ROUTES from '../../routers/helpersRouter/constantRouter'
import { MenuItem } from '../../types'

const icons = {
  RiShakeHandsLine,
  RiShakeHandsFill
}

export const customerMenuItem: MenuItem = createItem({
  id: 'list-customer',
  title: 'Khách hàng',
  url: `/${ROUTES.CUSTOMER}/${ROUTES.INDEX}`,
  icon: icons.RiShakeHandsLine,
  iconActive: icons.RiShakeHandsFill,
  premissions: [PERMISSION.ADMIN]
})
