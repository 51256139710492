import { createApi } from '@reduxjs/toolkit/query/react'
import { NetWork } from '../../../common/apiKey'
import { DELETE, GET, POST, PUT } from '../../../common/contants'
import { axiosBaseQuery } from '../../baseQuery'

export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['Order', 'OrderFast'],
  endpoints: (builder) => ({
    getListOrder: builder.query({
      query: (params) => ({
        url: NetWork.order,
        method: GET,
        params: params
      })
      // providesTags: (result: ReponseData2<{ rows: OrderType[] }>) => (result ? [{ type: 'Order', id: 'LIST' }] : [])
    }),
    getOrderById: builder.query({
      query: (params: { orderId: number }) => ({
        url: NetWork.orderId(params.orderId),
        method: GET
      })
    }),
    addOrder: builder.mutation({
      query: (data) => ({
        url: NetWork.order,
        method: POST,
        data: data
      })
    }),
    updateOrder: builder.mutation({
      query: (data) => ({
        url: NetWork.orderId(data.id),
        method: PUT,
        data: data
      })
    }),
    deleteOrder: builder.mutation({
      query: (data: { ids: Array<number | string> }) => ({
        url: NetWork.order,
        method: DELETE,
        data: data
      })
      // invalidatesTags: [{ type: 'Order', id: 'LIST' }] // Vô hiệu hóa tag 'Order' với id 'LIST' để gọi lại getListOrder
    }),
    //THÊM NHANH
    getListFastOrder: builder.query({
      query: (data) => ({
        url: NetWork.orderFast + '/' + data.uuid,
        method: GET,
        params: data.params
      }),
      providesTags: (result) => (result ? [{ type: 'OrderFast', id: 'LIST' }] : [])
    }),
    getFastOrderById: builder.query({
      query: (params: { orderId: number }) => ({
        url: NetWork.orderId(params.orderId),
        method: GET
      })
    }),
    addFastOrder: builder.mutation({
      query: (data) => ({
        url: NetWork.orderFast,
        method: POST,
        data: data
      }),
      invalidatesTags: [{ type: 'OrderFast', id: 'LIST' }]
    }),
    updateFastOrder: builder.mutation({
      query: (data) => ({
        url: NetWork.orderFastId(data.id),
        method: PUT,
        data: data
      }),
      invalidatesTags: [{ type: 'OrderFast', id: 'LIST' }]
    }),
    deleteFastOrder: builder.mutation({
      query: (data: { ids: Array<number | string> }) => ({
        url: NetWork.order,
        method: DELETE,
        data: data
      }),
      invalidatesTags: [{ type: 'OrderFast', id: 'LIST' }]
    }),
    connectFastOrder: builder.mutation({
      query: (data) => ({
        url: NetWork.orderFastConnectOrder(data.id),
        method: POST,
        data: data
      }),
      invalidatesTags: [{ type: 'OrderFast', id: 'LIST' }]
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetListOrderQuery,
  useDeleteOrderMutation,
  useAddOrderMutation,
  useGetOrderByIdQuery,
  useUpdateOrderMutation,
  useAddFastOrderMutation,
  useDeleteFastOrderMutation,
  useGetFastOrderByIdQuery,
  useGetListFastOrderQuery,
  useUpdateFastOrderMutation,
  useConnectFastOrderMutation
} = orderApi
