import colors from '../assets/scss/_themes-vars.module.scss'
import { COLORS } from './colors'
export const POST = 'POST'
export const PUT = 'PUT'
export const GET = 'GET'
export const DELETE = 'DELETE'
export const PATCH = 'PATCH'

export const LICENSE_KEY = process.env.REACT_APP_PUBLIC_MUI_LICENSE_KEY || ''
export const IS_DEV = process.env.REACT_APP_DEV ?? false

export const STATUS_SUCCESS = 'success'
export const STATUS_ERROR = 'error'

export const PADDINGMAINLAYOUT = '20px'

export const OPTIONGENDER = [
  { value: 'MALE', label: 'Nam' },
  { value: 'FEMALE', label: 'Nữ' },
  { value: 'OTHER', label: 'Khác' }
]

export const OPTIONSTATUS = [
  { value: '1', label: 'ON' },
  { value: '0', label: 'OFF' }
]

export const OPTIONTYPECUSTOMER = [
  { value: 'PERSON', label: 'Cá nhân' },
  { value: 'COMPANY', label: 'Công ty' }
]

export const KEY_OPTION__SALARY = {
  month: 'MONTH',
  order: 'ORDER'
}

export const OPTIONTYPESALARY = [
  { value: KEY_OPTION__SALARY.month, label: 'Lương tháng' },
  { value: KEY_OPTION__SALARY.order, label: 'Lương chuyến' }
]

export const TYPE_UPLOAD_IMG = {
  cccdStaff: 'staff/cccd', //Căn cước nhân viên
  cccdDriver: 'driver/cccd', //Căn cước lái
  gplxDriver: 'driver/gplx', //Giấy phép lái xe
  portraitDriver: 'driver/portrait', //Ảnh chân dung lái xe
  certDriver: 'driver/cert', //Chứng chỉ lái xe
  container: 'order/container', //Container
  seal: 'order/seal' //Chì
}

export const OPTION_UPLOAD_IMG_DRIVER = [
  { value: TYPE_UPLOAD_IMG.cccdDriver, label: 'Ảnh CCCD/CMND' },
  { value: TYPE_UPLOAD_IMG.certDriver, label: 'Ảnh chứng chỉ lái xe' },
  { value: TYPE_UPLOAD_IMG.portraitDriver, label: 'Ảnh chân dung lái xe' },
  { value: TYPE_UPLOAD_IMG.gplxDriver, label: 'Ảnh giấy phép lái xe' }
]

export const OPTION_UPLOAD_IMG_ORDER = [
  { value: TYPE_UPLOAD_IMG.container, label: 'Ảnh Container' },
  { value: TYPE_UPLOAD_IMG.seal, label: 'Ảnh chì' }
]

export const CONTANTS_TYPE_POSITION = {
  ADMIN: 'ADMIN',
  TP_KINH_DOANH: 'TP_KINH_DOANH',
  SALE: 'SALE',
  TP_KE_HOACH: 'TP_KE_HOACH',
  OP: 'OP',
  PRICING: 'PRICING',
  CUSTOMER_SERVICES: 'CUSTOMER_SERVICES',
  COORDINATION: 'COORDINATION',
  CUSTOMER_SUPPORT: 'CUSTOMER_SUPPORT',
  DRIVE: 'DRIVE',
  TP_KE_TOAN: 'TP_KE_TOAN',
  KE_TOAN_QUY: 'KE_TOAN_QUY',
  KE_TOAN_CONG_NO: 'KE_TOAN_CONG_NO',
  KE_TOAN_TONG_HOP: 'KE_TOAN_TONG_HOP'
}

export const OPTIONSPOSITION = [
  { value: CONTANTS_TYPE_POSITION.ADMIN, label: 'Admin', id: 1 },
  { value: CONTANTS_TYPE_POSITION.TP_KINH_DOANH, label: 'Trưởng phòng kinh doanh', id: 2 },
  { value: CONTANTS_TYPE_POSITION.SALE, label: 'Sale & Marketing', id: 3 },
  { value: CONTANTS_TYPE_POSITION.TP_KE_HOACH, label: 'Trưởng phòng kế hoạch', id: 4 },
  { value: CONTANTS_TYPE_POSITION.OP, label: 'Nhân viên hiện trường', id: 5 },
  { value: CONTANTS_TYPE_POSITION.PRICING, label: 'Nhân viên định mức', id: 6 },
  { value: CONTANTS_TYPE_POSITION.CUSTOMER_SERVICES, label: 'Nhân viên kế hoạch', id: 7 },
  { value: CONTANTS_TYPE_POSITION.COORDINATION, label: 'Điều phối và kỹ thuật', id: 8 },
  { value: CONTANTS_TYPE_POSITION.CUSTOMER_SUPPORT, label: 'Nhân viên theo dõi kế hoạch', id: 9 },
  { value: CONTANTS_TYPE_POSITION.DRIVE, label: 'Lái xe', id: 10 },
  { value: CONTANTS_TYPE_POSITION.TP_KE_TOAN, label: 'Trưởng phòng kế toán', id: 11 },
  { value: CONTANTS_TYPE_POSITION.KE_TOAN_QUY, label: 'Kế toán quỹ', id: 12 },
  { value: CONTANTS_TYPE_POSITION.KE_TOAN_CONG_NO, label: 'Kế toán công nợ', id: 13 },
  { value: CONTANTS_TYPE_POSITION.KE_TOAN_TONG_HOP, label: 'Kế toán tổng hợp', id: 13 }
]

export const OPTIONONOFF = [
  { value: '0', label: 'Offline' },
  { value: '1', label: 'Online' }
]

export const OPTIONOPENOFF = [
  { label: 'Mở', value: 'true' },
  { label: 'Đóng', value: 'false' }
]

export const TYPECARORDER = { vendor: 'vendor', company: 'company' }

export const OPTIONTYPECARORDER = [
  { label: 'Xe vendor', value: TYPECARORDER.vendor },
  { label: 'Xe công ty', value: TYPECARORDER.company }
]

export const OPTIONSTATUSWORK = [
  { value: 'WORKING', label: 'Đang làm việc' },
  { value: 'OUT', label: 'Đã nghỉ việc' },
  { value: 'STOP', label: 'Tạm nghỉ' }
]

export const OPTIONTYPEWORK = [
  { value: 'PROBATION', label: 'Thử việc' },
  { value: 'OFFICIAL', label: 'Chính thức' },
  { value: 'PART_TIME', label: 'Bán thời gian' }
]

export const OPTIONSTTWORKER = [
  { value: 'WAITING_FOR_INTERVIEW', label: 'Chờ phỏng vấn' },
  { value: 'INTERVIEW_FAILED', label: 'Phỏng vấn trượt' },
  { value: 'WORKING', label: 'Đang làm việc' },
  { value: 'WAITING_FOR_JOB', label: 'Đang chờ việc' },
  { value: 'LENT_TO_PARTNER', label: 'Cho vendor mượn' },
  { value: 'RESIGNED', label: 'Đã nghỉ việc' },
  { value: 'BORROWED_FROM_PARTNER', label: 'Mượn của vendor' }
]

export const STATUS_WORKING_EMPLOYEE = [
  { value: 'WAITING', label: 'Chờ phỏng vấn' },
  { value: 'FAIL', label: 'Phỏng vấn trượt' },
  { value: 'IN_HOME', label: 'Chờ giao việc' },
  { value: 'IN_COMPANY', label: 'Trong công ty' },
  { value: 'IN_PARTNER', label: 'Cho vendor mượn' },
  { value: 'OUT', label: 'Đã nghỉ việc' }
]

export const STATUS_ADVANCE_SALARY = [
  { value: 'WAITING_ACCEPT', label: 'Chờ duyệt', bg: 'yellow', color: 'black' },
  { value: 'ACCEPTED', label: 'Đã duyệt', bg: colors.successDark, color: 'white' },
  { value: 'REJECTED', label: 'Từ chối', bg: 'red', color: 'white' }
]

export const OPTION_COMPLETION = [
  { value: 'true', label: 'Đã hoàn ứng' },
  { value: 'false', label: 'Chưa hoàn' }
]

export const OPTION_HUMAN_RESOURCES = [
  { value: 'STAFF', label: 'Nhân viên' },
  { value: 'EMPLOYEE', label: 'Công nhân' }
]

export const OPTIONTYPEOLI = [
  { value: 'BAREM', label: 'Dầu khoán' },
  { value: 'ACTUAL', label: 'Dầu chuyến' }
]

//Filter advanced
export const OPTION_FILTER_CUSTOMER = [
  { value: 'all', label: 'Tất cả' },
  { value: 'identifier', label: 'Mã định danh' },
  { value: 'customerName', label: 'Tên khách hàng' },
  { value: 'shortName', label: 'Tên viết tắt' },
  { value: 'address', label: 'Địa chỉ' },
  { value: 'email', label: 'Email' },
  { value: 'firstSettlementDate', label: 'Ngày đầu chốt sổ' },
  { value: 'lastSettlementDate', label: 'Ngày cuối chốt sổ' }
]

export const OPTION_FILTER_COMPANYCAR = [
  { value: 'all', label: 'Tất cả' },
  { value: 'licensePlate', label: 'Biển số xe' },
  { value: 'tractorType', label: 'Loại đầu kéo' },
  { value: 'trailerNumber', label: 'Số Rơ Mooc' },
  { value: 'semiTrailerType', label: 'Loại sơ mi' },
  { value: 'driverName', label: 'Tên lái xe' },
  { value: 'phoneNumber', label: 'Số điện thoại' }
]

export const OPTION_FILTER_STAFF = [
  { value: 'all', label: 'Tất cả' },
  { value: 'name', label: 'Tên nhân viên' },
  { value: 'phoneNumber', label: 'Số điện thoại' },
  // { value: 'gender', label: 'Giới tính' },
  { value: 'address', label: 'Địa chỉ' },
  { value: 'username', label: 'Tài khoản' }
]

export const OPTION_CUSTOMER_FAKE = [
  { value: '1', label: 'Anh Dũng' },
  { value: '2', label: 'Cao Nguyễn' }
]

export const TYPE_STATUS_ORDER = {
  NEW: 'CREATED',
  COMPLATED: '1',
  CANCEL: 'CANCELLED'
}

export const OPTIONSTATUSORDER = [
  { value: TYPE_STATUS_ORDER.NEW, label: 'Tạo mới' },
  { value: TYPE_STATUS_ORDER.COMPLATED, label: 'Hoàn thành' },
  { value: TYPE_STATUS_ORDER.CANCEL, label: 'Hủy' }
]

export const checkColor = (val: string) => {
  switch (val) {
    case TYPE_STATUS_ORDER.NEW:
      return COLORS.originText
    case TYPE_STATUS_ORDER.COMPLATED:
      return COLORS.green
    case TYPE_STATUS_ORDER.CANCEL:
      return COLORS.red
    default:
      return COLORS.black
  }
}
export const checkBg = (val: string) => {
  switch (val) {
    case TYPE_STATUS_ORDER.NEW:
      return COLORS.origin
    case TYPE_STATUS_ORDER.COMPLATED:
      return COLORS.lightGreen
    case TYPE_STATUS_ORDER.CANCEL:
      return COLORS.lightRed
    default:
      return COLORS.black
  }
}

export const TOGGLE_OPTIONS = {
  OLD: 'old',
  NEW: 'new'
}

export const OPTION_TAX_RATE = [
  { value: '8', label: '8%' },
  { value: '10', label: '10%' },
  { value: '12', label: '12%' },
  { value: '15', label: '15%' }
]

//Order
export const FreightPrepaidFields = [
  'freightPrepaid.phiRu',
  'freightPrepaid.com',
  'freightPrepaid.cuoc',
  'freightPrepaid.lachHuyen',
  'freightPrepaid.namLai',
  'freightPrepaid.chiphikhacVanChuyen',
  'freightPrepaid.coSoHaTang',
  'freightPrepaid.nang',
  'freightPrepaid.ha',
  'freightPrepaid.can',
  'freightPrepaid.chiphikhacChiHo',
  'freightPrepaid.thongQuan',
  'freightPrepaid.kyHaiQuanGiamSat',
  'freightPrepaid.kiemHoa',
  'freightPrepaid.kiemDich',
  'freightPrepaid.hunTrung',
  'freightPrepaid.chiphikhacThongQuan'
]

export const FreightCollectFields = [
  'freightCollect.cuoc',
  'freightCollect.lachHuyen',
  'freightCollect.namLai',
  'freightCollect.chiphikhacVanChuyen',
  'freightCollect.coSoHaTang',
  'freightCollect.nang',
  'freightCollect.ha',
  'freightCollect.can',
  'freightCollect.chiphikhacChiHo',
  'freightCollect.thongQuan',
  'freightCollect.kyHaiQuanGiamSat',
  'freightCollect.kiemHoa',
  'freightCollect.kiemDich',
  'freightCollect.hunTrung',
  'freightCollect.chiphikhacThongQuan'
]

export const calculateConfigTotal = [
  'freightCollect.tongMonneyVanChuyen',
  // 'freightCollect.tongMonneyVatChiHo',
  'freightCollect.tongMonneyThongQuan',
  'freightPrepaid.tongMonneyVanChuyen',
  // 'freightPrepaid.tongMonneyVatChiHo',
  'freightPrepaid.tongMonneyThongQuan',
  'freightPrepaid.phiRu.monney',
  'freightPrepaid.com.monney'
]

export const calculateConfig = [
  {
    fields: [
      'freightPrepaid.cuoc',
      'freightPrepaid.lachHuyen',
      'freightPrepaid.namLai',
      'freightPrepaid.chiphikhacVanChuyen'
    ],
    totalFields: {
      monney: 'freightPrepaid.tongMonneyVanChuyen',
      vat: 'freightPrepaid.tongVatVanChuyen',
      monneyVat: 'freightPrepaid.tongMonneyVatVanChuyen'
    }
  },
  {
    fields: [
      'freightPrepaid.coSoHaTang',
      'freightPrepaid.nang',
      'freightPrepaid.ha',
      'freightPrepaid.can',
      'freightPrepaid.chiphikhacChiHo'
    ],
    totalFields: {
      monney: 'freightPrepaid.tongMonneyChiHo',
      vat: 'freightPrepaid.tongVatChiHo',
      monneyVat: 'freightPrepaid.tongMonneyVatChiHo'
    }
  },
  {
    fields: [
      'freightPrepaid.thongQuan',
      'freightPrepaid.kyHaiQuanGiamSat',
      'freightPrepaid.kiemHoa',
      'freightPrepaid.kiemDich',
      'freightPrepaid.hunTrung',
      'freightPrepaid.chiphikhacThongQuan'
    ],
    totalFields: {
      monney: 'freightPrepaid.tongMonneyThongQuan',
      vat: 'freightPrepaid.tongVatThongQuan',
      monneyVat: 'freightPrepaid.tongMonneyVatThongQuan'
    }
  },
  {
    fields: [
      'freightCollect.cuoc',
      'freightCollect.lachHuyen',
      'freightCollect.namLai',
      'freightCollect.chiphikhacVanChuyen'
    ],
    totalFields: {
      monney: 'freightCollect.tongMonneyVanChuyen',
      vat: 'freightCollect.tongVatVanChuyen',
      monneyVat: 'freightCollect.tongMonneyVatVanChuyen'
    }
  },
  {
    fields: [
      'freightCollect.coSoHaTang',
      'freightCollect.nang',
      'freightCollect.ha',
      'freightCollect.can',
      'freightCollect.chiphikhacChiHo'
    ],
    totalFields: {
      monney: 'freightCollect.tongMonneyChiHo',
      vat: 'freightCollect.tongVatChiHo',
      monneyVat: 'freightCollect.tongMonneyVatChiHo'
    }
  },
  {
    fields: [
      'freightCollect.thongQuan',
      'freightCollect.kyHaiQuanGiamSat',
      'freightCollect.kiemHoa',
      'freightCollect.kiemDich',
      'freightCollect.hunTrung',
      'freightCollect.chiphikhacThongQuan'
    ],
    totalFields: {
      monney: 'freightCollect.tongMonneyThongQuan',
      vat: 'freightCollect.tongVatThongQuan',
      monneyVat: 'freightCollect.tongMonneyVatThongQuan'
    }
  }
]
