import { lazy } from 'react'
import MainLayout from '../components/layout/MainLayout'
import Loadable from '../components/ui-component/Loadable'
import { PERMISSION } from '../constants'
import NotAuthorizedPage from '../page/notAuthor/NotAuthorizedPage'
import ROUTES from './helpersRouter/constantRouter'
import { createPrivateRoute, createProtectedRoute } from './helpersRouter/routeHelpers'

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../page/dashboard/DashboardPage')))

// admin routing
const AdminPage = Loadable(lazy(() => import('../page/admin/AdminPage')))

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('../page/utilities/Typography')))
const UtilsColor = Loadable(lazy(() => import('../page/utilities/Color')))
const UtilsShadow = Loadable(lazy(() => import('../page/utilities/Shadow')))
const UtilsInput = Loadable(lazy(() => import('../page/utilities/Input')))
const SamplePage = Loadable(lazy(() => import('../page/home/HomePage')))
const StaffPage = Loadable(lazy(() => import('../page/category/staff')))
// const StaffDetailPage = Loadable(lazy(() => import('../page/category/staff/detail')))
const PartnerPage = Loadable(lazy(() => import('../page/category/partner')))
const CompanyPage = Loadable(lazy(() => import('../page/category/company')))
//Xe công ty
const CompanyCarPage = Loadable(lazy(() => import('../page/companyCar')))
const DetailCompanyCarPage = Loadable(lazy(() => import('../page/companyCar/detail')))
//Lái xe
const DriverPage = Loadable(lazy(() => import('../page/driver')))
const DetailDriverPage = Loadable(lazy(() => import('../page/driver/detail')))
//
const ShellTypePage = Loadable(lazy(() => import('../page/category/shellType')))
const CarTypePage = Loadable(lazy(() => import('../page/category/carType')))
const ShippingLinesPage = Loadable(lazy(() => import('../page/category/shippingLines')))
const PaymentMethodPage = Loadable(lazy(() => import('../page/category/paymentMethod')))
const CurrencyPage = Loadable(lazy(() => import('../page/category/currency')))
const OrderTPage = Loadable(lazy(() => import('../page/category/orderType')))
const StaffTypePage = Loadable(lazy(() => import('../page/category/staffType')))
const CustomerPage = Loadable(lazy(() => import('../page/category/customer')))
const DetailCustomerPage = Loadable(lazy(() => import('../page/category/customer/detail')))
const AddCustomerPage = Loadable(lazy(() => import('../page/category/customer/add/AddCustomer')))

//Salary
// const SalaryAdvancePage = Loadable(lazy(() => import('../page/category/salary/salary_advance')))
// const SalaryPayPage = Loadable(lazy(() => import('../page/category/salary/salary-pay')))

//Order
const RequestOrderPage = Loadable(lazy(() => import('../page/order/requestOrder')))
const PriceBasePage = Loadable(lazy(() => import('../page/order/priceBase')))

//Lịch sử chi
const CostOliPage = Loadable(lazy(() => import('../page/genusHistory/costOil')))
const CostGeneralPage = Loadable(lazy(() => import('../page/genusHistory/costGeneral')))
const CostRepairPage = Loadable(lazy(() => import('../page/genusHistory/costRepair')))
const CostRoadPage = Loadable(lazy(() => import('../page/genusHistory/costRoad')))
const CostBonusPage = Loadable(lazy(() => import('../page/genusHistory/costBonus')))

//Định mức
const NormsSalaryMonthPage = Loadable(lazy(() => import('../page/norms/normsSalaryMonth')))
const NormSalaryOrderPage = Loadable(lazy(() => import('../page/norms/normSalaryOrder')))
const NormsDegradationPage = Loadable(lazy(() => import('../page/norms/normsDegradation')))

//Đơn hàng
const ListOrderPage = Loadable(lazy(() => import('../page/order/list')))
const AddOrderNewFastPage = Loadable(lazy(() => import('../page/order/addOrderFast')))
const AddNewOrderPage = Loadable(lazy(() => import('../page/order/addNewOrder')))

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: ROUTES.HOME,
  element: createPrivateRoute(<MainLayout />),
  children: [
    {
      path: ROUTES.STATISTICAL_CHILD.REQUEST,
      children: [
        {
          path: ROUTES.INDEX,
          element: <DashboardDefault />
        }
      ]
    },
    // {
    //   path: ROUTES.ORDER,
    //   children: [
    //     {
    //       path: ROUTES.DEFAULT,
    //       element: <AdminPage />
    //     },
    //     {
    //       path: ROUTES.ORDER_DETAIL,
    //       element: <StaffPage />
    //     }
    //   ]
    // },
    {
      path: ROUTES.STATISTICAL,
      children: [
        {
          path: ROUTES.STATISTICAL_CHILD.REQUEST,
          element: <RequestOrderPage />
        },
        {
          path: ROUTES.STATISTICAL_CHILD.BASE_PRICE,
          element: <PriceBasePage />
        }
      ]
    },
    {
      path: ROUTES.CUSTOMER,
      children: [
        {
          path: ROUTES.INDEX,
          element: <CustomerPage />
        },
        {
          path: ROUTES.CUSTOMER_ADD,
          element: <AddCustomerPage />
        },
        {
          path: ROUTES.CUSTOMER_DETAIL_ID,
          element: <DetailCustomerPage />
        }
      ]
    },
    {
      path: ROUTES.COMPANY,
      children: [
        {
          path: ROUTES.COMPANY_CHILD.CAR,
          element: createProtectedRoute(<CompanyCarPage />, [PERMISSION.ADMIN])
        },
        {
          path: ROUTES.COMPANY_CHILD.CAR_DETAIL,
          element: createProtectedRoute(<DetailCompanyCarPage />, [PERMISSION.ADMIN])
        },
        {
          path: ROUTES.COMPANY_CHILD.DRIVER,
          element: createProtectedRoute(<DriverPage />, [PERMISSION.ADMIN])
        },
        {
          path: ROUTES.COMPANY_CHILD.DRIVER_DETAIL,
          element: createProtectedRoute(<DetailDriverPage />, [PERMISSION.ADMIN])
        },
        {
          path: ROUTES.COMPANY_CHILD.COST_OLI,
          element: createProtectedRoute(<CostOliPage />, [PERMISSION.ADMIN])
        },
        {
          path: ROUTES.COMPANY_CHILD.COST_REPAIR,
          element: createProtectedRoute(<CostRepairPage />, [PERMISSION.ADMIN])
        },
        {
          path: ROUTES.COMPANY_CHILD.COST_GENERAL,
          element: createProtectedRoute(<CostGeneralPage />, [PERMISSION.ADMIN])
        },
        {
          path: ROUTES.COMPANY_CHILD.COST_ROAD,
          element: createProtectedRoute(<CostRoadPage />, [PERMISSION.ADMIN])
        },
        {
          path: ROUTES.COMPANY_CHILD.COST_BONUS,
          element: createProtectedRoute(<CostBonusPage />, [PERMISSION.ADMIN])
        },
        {
          path: ROUTES.COMPANY_CHILD.NORMS_SALARY_MONTH,
          element: createProtectedRoute(<NormsSalaryMonthPage />, [PERMISSION.ADMIN])
        },
        {
          path: ROUTES.COMPANY_CHILD.NORMS_SALARY_ORDERS,
          element: createProtectedRoute(<NormSalaryOrderPage />, [PERMISSION.ADMIN])
        },
        {
          path: ROUTES.COMPANY_CHILD.NORMS_DEGRADATION,
          element: createProtectedRoute(<NormsDegradationPage />, [PERMISSION.ADMIN])
        }
      ]
    },
    {
      path: ROUTES.UTILS,
      children: [
        {
          path: ROUTES.UTILS_CHILD.TYPOGRAPHY,
          element: <UtilsTypography />
        },
        {
          path: ROUTES.UTILS_CHILD.SHADOW,
          element: <UtilsShadow />
        },
        {
          path: ROUTES.UTILS_CHILD.COLOR,
          element: <UtilsColor />
        },
        {
          path: ROUTES.UTILS_CHILD.INPUT,
          element: <UtilsInput />
        }
      ]
    },
    {
      path: ROUTES.CATEGORY,
      children: [
        {
          path: ROUTES.CATEGORY_CHILD.STAFF,
          element: createProtectedRoute(<StaffPage />, [
            PERMISSION.ADMIN,
            PERMISSION.GIAMDOC,
            PERMISSION.KETOAN,
            PERMISSION.HCNS
          ])
        },
        // {
        //   path: ROUTES.CATEGORY_CHILD.STAFF_DETAIL,
        //   // element: <StaffDetailPage />,
        //   element: createProtectedRoute(<StaffDetailPage />, [
        //     PERMISSION.ADMIN,
        //     PERMISSION.GIAMDOC,
        //     PERMISSION.KETOAN,
        //     PERMISSION.SALE,
        //     PERMISSION.HCNS,
        //     PERMISSION.TUYENDUNG
        //   ])
        // },
        {
          path: ROUTES.CATEGORY_CHILD.PARTER,
          element: <PartnerPage />
        },
        {
          path: ROUTES.CATEGORY_CHILD.COMPANY,
          element: <CompanyPage />
        },
        {
          path: ROUTES.CATEGORY_CHILD.SHELL_TYPE,
          element: <ShellTypePage />
        },
        {
          path: ROUTES.CATEGORY_CHILD.CAR_TYPE,
          element: <CarTypePage />
        },
        {
          path: ROUTES.CATEGORY_CHILD.SHIPPING_LINES,
          element: <ShippingLinesPage />
        },
        {
          path: ROUTES.CATEGORY_CHILD.CURRENCY,
          element: <CurrencyPage />
        },
        {
          path: ROUTES.CATEGORY_CHILD.ORDER_TYPE,
          element: <OrderTPage />
        },
        {
          path: ROUTES.CATEGORY_CHILD.STAFF_TYPE,
          element: <StaffTypePage />
        },
        {
          path: ROUTES.CATEGORY_CHILD.PAYMENT_METHOD,
          element: <PaymentMethodPage />
        }
        // {
        //   path: ROUTES.CATEGORY_CHILD.SALARY_ADVANCE,
        //   // element: <SalaryAdvancePage />
        //   element: createProtectedRoute(<SalaryAdvancePage />, [
        //     PERMISSION.ADMIN,
        //     PERMISSION.GIAMDOC,
        //     PERMISSION.KETOAN,
        //     PERMISSION.SALE,
        //     PERMISSION.TUYENDUNG
        //   ])
        // },
        // {
        //   path: ROUTES.CATEGORY_CHILD.SALARY_PAY_STAFF,
        //   // element: <SalaryPayPage type='STAFF' />,
        //   element: createProtectedRoute(<SalaryPayPage type='STAFF' />, [
        //     PERMISSION.ADMIN,
        //     PERMISSION.GIAMDOC,
        //     PERMISSION.KETOAN
        //   ])
        // }
      ]
    },
    {
      path: ROUTES.ORDER,
      children: [
        {
          path: ROUTES.ORDER_CHILD.ORDER_LIST,
          element: createProtectedRoute(<ListOrderPage />, [PERMISSION.ADMIN])
        },
        {
          path: ROUTES.ORDER_CHILD.ORDER_ORDER_NEW,
          element: createProtectedRoute(<AddNewOrderPage />, [PERMISSION.ADMIN])
        },
        {
          path: ROUTES.ORDER_CHILD.ORDER_ORDER_FAST,
          element: createProtectedRoute(<AddOrderNewFastPage />, [PERMISSION.ADMIN])
        },
        {
          path: ROUTES.ORDER_CHILD.ORDER_SHIPMENT,
          element: createProtectedRoute(<SamplePage />, [PERMISSION.ADMIN])
        }
      ]
    },
    {
      path: ROUTES.SAMPLE_PAGE,
      element: createProtectedRoute(<SamplePage />, [PERMISSION.SALE])
    },
    {
      path: ROUTES.NOT_AUTHORIZED,
      element: <NotAuthorizedPage />
    }
  ]
}

export default MainRoutes
