import { RiProfileLine, RiProfileFill } from '@remixicon/react'
import { PERMISSION } from '../../constants'
import { createCollapseGroup, createItem } from '../../help'
import ROUTES from '../../routers/helpersRouter/constantRouter'
import { MenuItem } from '../../types'

const icons = {
  RiProfileLine,
  RiProfileFill
}

export const orderMenuItem: MenuItem[] = [
  createCollapseGroup({
    id: 'order',
    title: 'Quản lý đơn hàng',
    icon: icons.RiProfileLine,
    iconActive: icons.RiProfileFill,
    premissions: [PERMISSION.ADMIN],
    children: [
      createItem({
        id: 'order-list',
        title: 'Đơn hàng',
        url: `/${ROUTES.ORDER}/${ROUTES.ORDER_CHILD.ORDER_LIST}`,
        premissions: [PERMISSION.ADMIN]
      }),
      createItem({
        id: 'order-shipment',
        title: 'Lô hàng',
        url: `/${ROUTES.ORDER}/${ROUTES.ORDER_CHILD.ORDER_SHIPMENT}`,
        premissions: [PERMISSION.ADMIN]
      })
      //   createCollapseGroup({
      //     id: 'order-genus-history',
      //     title: 'Lịch sử chi',
      //     premissions: [PERMISSION.ADMIN],
      //     children: [
      //       createItem({
      //         id: 'order-genus-history-oli',
      //         title: 'Dầu không khoán',
      //         url: `/${ROUTES.ORDER}/${ROUTES.ORDER_CHILD.ORDER_LIST}`,
      //         premissions: [PERMISSION.ADMIN]
      //       }),
      //       createItem({
      //         id: 'order-edit-repair',
      //         title: 'Sửa chữa',
      //         url: `/${ROUTES.ORDER}/${ROUTES.ORDER_CHILD.ORDER_LIST}`,
      //         premissions: [PERMISSION.ADMIN]
      //       }),
      //       createItem({
      //         id: 'order-cost-general',
      //         title: 'Quản lý chung',
      //         url: `/${ROUTES.ORDER}/${ROUTES.ORDER_CHILD.ORDER_LIST}`,
      //         premissions: [PERMISSION.ADMIN]
      //       }),
      //       createItem({
      //         id: 'order-cost-road',
      //         title: 'Tiền đường',
      //         url: `/${ROUTES.ORDER}/${ROUTES.ORDER_CHILD.ORDER_LIST}`,
      //         premissions: [PERMISSION.ADMIN]
      //       }),
      //       createItem({
      //         id: 'order-cost-bonus',
      //         title: 'Thưởng',
      //         url: `/${ROUTES.ORDER}/${ROUTES.ORDER_CHILD.ORDER_LIST}`,
      //         premissions: [PERMISSION.ADMIN]
      //       })
      //     ]
      //   }),
      //   createCollapseGroup({
      //     id: 'order-norms',
      //     title: 'Định mức thanh toán',
      //     premissions: [PERMISSION.ADMIN],
      //     children: [
      //       createItem({
      //         id: 'order-norms-salary-month',
      //         title: 'Lương tháng',
      //         url: `/${ROUTES.ORDER}/${ROUTES.ORDER_CHILD.ORDER_LIST}`,
      //         premissions: [PERMISSION.ADMIN]
      //       }),
      //       createItem({
      //         id: 'order-norms-salary-order',
      //         title: 'Lương chuyến',
      //         url: `/${ROUTES.ORDER}/${ROUTES.ORDER_CHILD.ORDER_LIST}`,
      //         premissions: [PERMISSION.ADMIN]
      //       }),
      //       createItem({
      //         id: 'order-norms-degradation',
      //         title: 'Định mức tiêu hao xe',
      //         url: `/${ROUTES.ORDER}/${ROUTES.ORDER_CHILD.ORDER_LIST}`,
      //         premissions: [PERMISSION.ADMIN]
      //       })
      //     ]
      //   })
    ]
  })
]
