// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import {
  ClickAwayListener,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useMediaQuery
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react'
import React, { useEffect, useRef, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../../../../../app/hooks'
import { customTheme } from '../../../../../../app/selectedStore'
import { MenuItem } from '../../../../../../types'
import MainCard from '../../../../../ui-component/cards/MainCard'
import Transitions from '../../../../../ui-component/extended/Transitions'
import NavItem from '../NavItem'

interface NavCollapseProps {
  menu: MenuItem
  level: number
}

const NavCollapse: React.FC<NavCollapseProps> = ({ menu, level }) => {
  const theme = useTheme()
  const customization = useAppSelector(customTheme)
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const [open, setOpen] = useState(false)
  const [hovered, setHovered] = useState(false)
  const [selected, setSelected] = useState<string>('')
  const { pathname } = useLocation()
  const anchorRef = useRef<HTMLDivElement>(null)
  const popperRef = useRef<HTMLDivElement>(null)
  const openDrawer = customization?.opened

  const handleClick = () => {
    setOpen(!open)
    setSelected(selected === menu.id ? '' : menu.id)
  }

  const handleMouseEnter = () => {
    if (!openDrawer) setHovered(true)
  }

  // Hàm xử lý khi chuột di chuyển ra ngoài
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleMouseLeave = (event: any) => {
    /* eslint-enable @typescript-eslint/no-explicit-any */
    let relatedTarget: Node | null = null

    if (event instanceof MouseEvent) {
      relatedTarget = event.relatedTarget as Node
    }

    if (!popperRef.current?.contains(relatedTarget) && !anchorRef.current?.contains(relatedTarget)) {
      setHovered(false)
    }
  }

  const handlePopperMouseEnter = () => {
    setHovered(true)
  }

  const handlePopperMouseLeave = (event: React.MouseEvent) => {
    if (!anchorRef.current?.contains(event.relatedTarget as Node)) {
      setHovered(false)
    }
  }

  const checkActive = (items: MenuItem[]) => {
    for (const item of items) {
      if (item.url) {
        const basePath = level < 2 && item.url.split('/').slice(0, 2).join('/')
        const baseMatch = matchPath(
          { path: `${basePath}/*` }, // Chấp nhận các route con
          pathname
        )
        const exactMatch = matchPath({ path: item.url, end: true }, pathname)
        const dynamicMatch = matchPath(
          { path: item.url.replace(/\/\d+$/, ''), end: true },
          pathname.replace(/\/\d+$/, '')
        )

        if (exactMatch || dynamicMatch || baseMatch) {
          setSelected(menu.id)
          setOpen(true)
          return true
        }
      }

      // Kiểm tra các cấp con (children)
      if (item.children && checkActive(item.children)) {
        setSelected(menu.id)
        setOpen(true)
        return true
      }
    }
    return false
  }

  useEffect(() => {
    setOpen(false)
    setSelected('')
    // if (menu.children) {
    //   menu.children.forEach((item) => {
    //     if (item.url) {
    //       const exactMatch = matchPath({ path: item.url, end: true }, pathname)
    //       const dynamicMatch = matchPath(
    //         { path: item.url.replace(/\/\d+$/, ''), end: true },
    //         pathname.replace(/\/\d+$/, '')
    //       )
    //       console.log(exactMatch, dynamicMatch, pathname, item.url)

    //       if (exactMatch || dynamicMatch) {
    //         setSelected(menu.id)
    //         setOpen(true)
    //       }
    //     }
    //   })
    // }

    if (menu.children) {
      checkActive(menu.children)
    }
  }, [pathname, menu.children, openDrawer])

  const menus = menu.children?.map((item) => {
    switch (item.type) {
      case 'collapse':
        return <NavCollapse key={item.id} menu={item} level={level + 1} />
      case 'item':
        return <NavItem key={item.id} item={item} level={level + 1} />
      default:
        return (
          <Typography key={item.id} variant='h6' color='error' align='center'>
            Menu Items Error
          </Typography>
        )
    }
  })

  const Icon = selected ? (menu.iconActive ? menu.iconActive : menu.icon) : menu.icon
  const menuIcon = Icon ? (
    <Icon strokeWidth={1.5} size='1.3rem' style={{ marginTop: 'auto', marginBottom: 'auto' }} />
  ) : (
    // <FiberManualRecordIcon
    //   sx={{ width: selected === menu.id ? 8 : 6, height: selected === menu.id ? 8 : 6 }}
    //   fontSize={level > 0 ? 'inherit' : 'medium'}
    // />
    <></>
  )

  return (
    <>
      <ListItemButton
        aria-expanded={open}
        aria-controls={`collapse-${menu.id}`}
        ref={anchorRef}
        sx={{
          borderRadius: `${customization.borderRadius}px`,
          mb: 0.5,
          alignItems: 'center',
          backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
          py: level > 1 ? 1 : 1.25,
          pl: openDrawer || matchDownMd ? `${level * 20}px` : '14px',
          '& .MuiListItemIcon-root': {
            display: level > 1 && !openDrawer && !matchDownMd ? 'none' : 'block'
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main, // Màu khi được chọn
            '& .MuiListItemIcon-root': {
              color: theme.palette.common.white // Đổi màu icon khi được chọn
              // display: level > 1 && !openDrawer ? 'none' : 'block'
            },
            '&:hover': {
              backgroundColor: '#0075E8' // Màu khi hover trong trạng thái selected
            }
          },
          '&.Mui-focusVisible': {
            backgroundColor: theme.palette.primary.main // Màu khi focus
          },
          '&:hover': {
            backgroundColor: '#E0F3FF' // Màu khi hover nếu không được chọn
          }
        }}
        selected={selected === menu.id}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>
        {(openDrawer || level > 1 || matchDownMd) && (
          <ListItemText
            primary={
              <Typography
                variant={selected === menu.id ? 'h5' : 'body1'}
                color={
                  selected === menu.id ? (level > 1 ? theme.palette.primary.main : theme.palette.common.white) : ''
                }
                sx={{ my: 'auto' }}
              >
                {menu.title}
              </Typography>
            }
            secondary={
              menu.caption && (
                <Typography variant='caption' sx={{ ...theme.typography.subMenuCaption }} display='block' gutterBottom>
                  {menu.caption}
                </Typography>
              )
            }
          />
        )}
        {!openDrawer ? (
          <IconChevronRight
            size='1rem'
            style={{ color: selected === menu.id && level < 2 ? theme.palette.common.white : 'inherit' }}
          />
        ) : openDrawer || level > 1 || matchDownMd ? (
          open ? (
            <IconChevronDown
              size='1rem'
              style={{ color: selected === menu.id && level < 2 ? theme.palette.common.white : 'inherit' }}
            />
          ) : (
            <IconChevronRight
              size='1rem'
              style={{ color: selected === menu.id && level < 2 ? theme.palette.common.white : 'inherit' }}
            />
          )
        ) : null}
      </ListItemButton>

      {openDrawer || matchDownMd ? (
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {menus}
          </List>
        </Collapse>
      ) : (
        <Popper
          open={hovered}
          anchorEl={anchorRef.current}
          placement='right-start'
          role='tooltip'
          transition
          onMouseEnter={handlePopperMouseEnter}
          onMouseLeave={handlePopperMouseLeave}
          popperOptions={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, level >= 2 ? 20 * level - 20 : 20] // Tăng giá trị x từ 80 lên 100 để đẩy sang phải thêm
                }
              }
            ]
          }}
        >
          {({ TransitionProps }) => (
            <Transitions in={hovered} {...TransitionProps}>
              <Paper elevation={8} ref={popperRef}>
                <ClickAwayListener onClickAway={handleMouseLeave}>
                  <MainCard
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                    sx={{ p: 2 }}
                  >
                    {/* <ListItemText
                      primary={
                        <Typography variant={selected === menu.id ? 'h5' : 'body1'} color={''} sx={{ my: 'auto' }}>
                          {menu.title}
                        </Typography>
                      }
                      secondary={
                        menu.caption && (
                          <Typography
                            variant='caption'
                            sx={{ ...theme.typography.subMenuCaption }}
                            display='block'
                            gutterBottom
                          >
                            {menu.caption}
                          </Typography>
                        )
                      }
                    /> */}
                    <Typography
                      variant='caption'
                      sx={{ ...theme.typography.subMenuCaption, textTransform: 'uppercase' }}
                      display='block'
                      gutterBottom
                    >
                      {menu.title}
                    </Typography>
                    <List
                      component='nav'
                      sx={{
                        width: '100%',
                        maxWidth: 350,
                        minWidth: 120,
                        backgroundColor: theme.palette.background.paper
                      }}
                    >
                      {menus}
                    </List>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            </Transitions>
          )}
        </Popper>
      )}
    </>
  )
}

export default NavCollapse
