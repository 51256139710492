import { configureStore } from '@reduxjs/toolkit'

//Slice
import counterReducer from './features/counter/counterSlice'
import authReducer from './features/auth/authSlice'
import customizationReducer from './features/customization/customizationSlice'
import customerReducer from './features/customer/customerSlice'
import orderReducer from './features/order/orderSlice'

//Api
import { pokemonApi } from './services/pokemon'
import { authApi } from './services/auth'
import { staffApi } from './services/staff'
import { companyApi } from './services/company'
import { partnerApi } from './services/partner'
import { employeeApi } from './services/employee'
import { salaryAdvanceApi } from './services/salaryAdvance'
import { salaryRefundApi } from './services/salaryRefund'
import { salaryPayApi } from './services/salaryPay'
import { statisticApi } from './services/statistic'
import { companyCarApi } from './services/companyCar'
import { shellTypeApi } from './services/shellType'
import { carTypeApi } from './services/carType'
import { shippingLinesApi } from './services/shippingLines'
import { paymentMethodApi } from './services/paymentMethod'
import { currencyApi } from './services/currency'
import { orderTypeApi } from './services/orderType'
import { staffTypeApi } from './services/staffType'
import { customerApi } from './services/customer'
import { requestOrderApi } from './services/requestOrder'
import { priceBaseApi } from './services/priceBase'
import { driverApi } from './services/driver'
import { costOliApi } from './services/genusHistory/costOli'
import { costRepairApi } from './services/genusHistory/costRepair'
import { costGeneralApi } from './services/genusHistory/costGeneral'
import { costRoadApi } from './services/genusHistory/costRoad'
import { costBonusApi } from './services/genusHistory/costBonus'
import { normsSalaryMonthApi } from './services/norms/normsSalaryMonth'
import { normSalaryOrderApi } from './services/norms/normSalaryOrder'
import { normsDegradationApi } from './services/norms/normsDegradation'
import { orderApi } from './services/order'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    customization: customizationReducer,
    customer: customerReducer,
    order: orderReducer,
    //RTKQR
    [pokemonApi.reducerPath]: pokemonApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    //Category
    [staffApi.reducerPath]: staffApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [partnerApi.reducerPath]: partnerApi.reducer,
    [employeeApi.reducerPath]: employeeApi.reducer,
    [salaryAdvanceApi.reducerPath]: salaryAdvanceApi.reducer,
    [salaryRefundApi.reducerPath]: salaryRefundApi.reducer,
    [salaryPayApi.reducerPath]: salaryPayApi.reducer,
    [statisticApi.reducerPath]: statisticApi.reducer,
    [companyCarApi.reducerPath]: companyCarApi.reducer,
    [shellTypeApi.reducerPath]: shellTypeApi.reducer,
    [carTypeApi.reducerPath]: carTypeApi.reducer,
    [shippingLinesApi.reducerPath]: shippingLinesApi.reducer,
    [paymentMethodApi.reducerPath]: paymentMethodApi.reducer,
    [currencyApi.reducerPath]: currencyApi.reducer,
    [orderTypeApi.reducerPath]: orderTypeApi.reducer,
    [staffTypeApi.reducerPath]: staffTypeApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    //Order
    [requestOrderApi.reducerPath]: requestOrderApi.reducer,
    [priceBaseApi.reducerPath]: priceBaseApi.reducer,
    //Driver
    [driverApi.reducerPath]: driverApi.reducer,
    //genusHistory
    [costOliApi.reducerPath]: costOliApi.reducer,
    [costRepairApi.reducerPath]: costRepairApi.reducer,
    [costGeneralApi.reducerPath]: costGeneralApi.reducer,
    [costRoadApi.reducerPath]: costRoadApi.reducer,
    [costBonusApi.reducerPath]: costBonusApi.reducer,
    //norms
    [normsSalaryMonthApi.reducerPath]: normsSalaryMonthApi.reducer,
    [normSalaryOrderApi.reducerPath]: normSalaryOrderApi.reducer,
    [normsDegradationApi.reducerPath]: normsDegradationApi.reducer,
    //Order
    [orderApi.reducerPath]: orderApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      pokemonApi.middleware,
      authApi.middleware,
      //Category
      staffApi.middleware,
      companyApi.middleware,
      partnerApi.middleware,
      employeeApi.middleware,
      salaryAdvanceApi.middleware,
      salaryRefundApi.middleware,
      salaryPayApi.middleware,
      statisticApi.middleware,
      companyCarApi.middleware,
      shellTypeApi.middleware,
      carTypeApi.middleware,
      shippingLinesApi.middleware,
      paymentMethodApi.middleware,
      currencyApi.middleware,
      orderTypeApi.middleware,
      staffTypeApi.middleware,
      customerApi.middleware,
      //Order
      requestOrderApi.middleware,
      priceBaseApi.middleware,
      driverApi.middleware,
      //genusHistory
      costOliApi.middleware,
      costRepairApi.middleware,
      costGeneralApi.middleware,
      costRoadApi.middleware,
      costBonusApi.middleware,
      //Norms
      normsSalaryMonthApi.middleware,
      normSalaryOrderApi.middleware,
      normsDegradationApi.middleware,
      orderApi.middleware
    )
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
