import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import Toast from '../components/toast'
import { FilterAdvancedType } from '../types'
import type { AppDispatch, RootState } from './store'
import { useWatch } from 'react-hook-form'
import React from 'react'
import { FeeType } from '../types/order'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const Numberformat = function (number: number) {
  return new Intl.NumberFormat('vi-VN', {
    // minimumFractionDigits: 2,
  }).format(number)
}

//Format curency
export const currency = function (number: number) {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND'
    // minimumFractionDigits: 2,
  }).format(number)
}

export function formatNumber(value: number): string {
  return value.toLocaleString('vi-VN')
}

export const handleMutation = (props: {
  loading: boolean
  isError: boolean
  isSuccess: boolean
  successMessage: string
  errorMessage: string
  refetch: () => void
}) => {
  if (!props.loading) {
    props.isError && Toast({ text: props.errorMessage, variant: 'error' })
    props.isSuccess && Toast({ text: props.successMessage, variant: 'success' }) && props.refetch()
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export const convertDataLabel = ({ data, key, value }: { data: any; key: string; value: string }) => {
  const newData = data.map((e: any) => ({ ...e, label: e?.[key] || '', value: e?.[value] || '' }))
  return newData || []
}

export const convertDataLabelAutoComplate = ({
  data,
  key,
  value,
  subKey
}: {
  data: any
  key: string
  value: string
  subKey?: string
}) => {
  const newData = data.map((e: any) => ({
    ...e,
    label: (e?.[key] || '') + (subKey ? `- ${e?.[subKey]}` : ''),
    value: e?.[value].toString() || ''
  }))
  return newData || []
}

// Lấy param
export const useQueryParam = (paramName: string, defaultValue: any) => {
  const [searchParams] = useSearchParams()
  const paramValue = searchParams.get(paramName)

  const isJson = (str: string) => {
    try {
      JSON.parse(str)
      return true
    } catch {
      return false
    }
  }

  if (paramValue) {
    if (isJson(paramValue)) {
      try {
        const val = JSON.parse(paramValue)
        if (typeof val == 'boolean') return val
        return val || defaultValue
      } catch (error) {
        console.error(`Error parsing ${paramName}:`, error)
        return defaultValue
      }
    } else {
      // Trả về chuỗi gốc nếu không phải là JSON
      return paramValue
    }
  }

  return defaultValue
}

// Hàm tạo params từ paginationModel và filters
export const useCreateSearchParams = (
  paginationModel: { page: number; pageSize: number },
  filters: { [field: string]: string | FilterAdvancedType[] }
) => {
  const params: { [key: string]: string } = {}
  const fields = {
    page: paginationModel.page.toString(),
    pageSize: paginationModel.pageSize.toString(),
    ...filters
  }

  // Chỉ thêm các trường hợp có giá trị
  Object.entries(fields).forEach(([key, value]) => {
    if (typeof value == 'boolean') return (params[key] = value)
    if (value) {
      // Xử lý mảng nếu có
      if (Array.isArray(value)) {
        params[key] = JSON.stringify(value)
      } else {
        params[key] = value // Gán giá trị cho các trường khác
      }
    }
  })

  return params
}

/* eslint-enable @typescript-eslint/no-explicit-any */

//Hàm tính toán thay đổi field đơn
/* eslint-disable @typescript-eslint/no-explicit-any */
export function formatNumberToFix(value: number, toFix = 2): string {
  if (Number.isNaN(value)) return '' // Xử lý nếu không phải số
  const formatted = value.toFixed(toFix)
  return formatted.endsWith(`.${'0'.repeat(toFix)}`) ? `${Math.floor(value)}` : formatted
}

export function useFieldCalculation(
  field: string,
  control: any,
  calculateFees: (data?: FeeType, field?: string) => any,
  calculateTotalMoneyVatFees: (data?: FeeType, field?: string) => any,
  setValue: any
) {
  const watchedMonney = useWatch({ name: `${field}.monney`, control })
  const watchedVat = useWatch({ name: `${field}.vat`, control })
  const watchedMonneyVat = useWatch({ name: `${field}.monneyVat`, control })

  React.useEffect(() => {
    if (watchedMonney !== undefined || watchedVat !== undefined) {
      const result = calculateFees({ monney: watchedMonney, vat: watchedVat }, field)
      setValue(`${field}.monneyVat`, result.monneyVat)
      setValue(`${field}.totalMonneyVat`, result.totalMonneyVat)
    }
  }, [watchedMonney, watchedVat, calculateFees, field, setValue])

  React.useEffect(() => {
    if (watchedMonney !== undefined && watchedMonneyVat !== undefined) {
      const result = calculateTotalMoneyVatFees({ monney: watchedMonney, monneyVat: watchedMonneyVat }, field)
      setValue(`${field}.totalMonneyVat`, result.totalMonneyVat)
    }
  }, [watchedMonney, watchedMonneyVat, calculateTotalMoneyVatFees, field, setValue])
}

export const calculateAndSetTotals = (
  fields: string[], // Danh sách các trường cần theo dõi
  totalFields: {
    // Các trường tổng
    monney: string
    vat: string
    monneyVat: string
  },
  control: any, // React Hook Form control
  setValue: any // React Hook Form setValue
) => {
  const multipleValues = useWatch({ name: fields, control })

  React.useEffect(() => {
    let totalMonney = 0
    let totalVat = 0
    let totalMonneyVat = 0

    multipleValues.forEach((fee: FeeType) => {
      const monney = parseFloat(fee?.monney || '0')
      const vat = parseFloat(fee?.monneyVat || '0')
      const monneyVat = parseFloat(fee?.totalMonneyVat || '0')

      totalMonney += monney
      totalVat += vat
      totalMonneyVat += monneyVat
    })

    setValue(totalFields.monney, formatNumberToFix(totalMonney))
    setValue(totalFields.vat, formatNumberToFix(totalVat))
    setValue(totalFields.monneyVat, formatNumberToFix(totalMonneyVat))
  }, [multipleValues, fields, setValue]) // Theo dõi thay đổi của các trường
}

export const useFieldCalculateRevenueExpenditureProfit = (fields: string[], control: any, setValue: any) => {
  const multipleValues = useWatch({ name: fields, control }) as (string | undefined)[]

  React.useEffect(() => {
    let totalFreightCollect = 0 // Tổng thu
    let totalFreightPrepaid = 0 // Tổng chi
    let totalCom = 0 // Tổng chi
    // Duyệt qua các trường để tính toán
    fields.forEach((field, index) => {
      const value = parseFloat(multipleValues[index] || '0')
      if (field === 'freightCollect.tongMonneyVanChuyen' || field === 'freightCollect.tongMonneyThongQuan') {
        totalFreightCollect += value
      }

      if (
        field === 'freightPrepaid.tongMonneyVanChuyen' ||
        field === 'freightPrepaid.tongMonneyThongQuan' ||
        field === 'freightPrepaid.phiRu.monney' ||
        field === 'freightPrepaid.com.monney'
      ) {
        totalFreightPrepaid += value
      }

      if (field === 'freightPrepaid.com.monney') {
        totalCom += value
      }
    })

    // Tổng chi bao gồm com
    const totalCost = totalFreightPrepaid

    // Tính lợi nhuận
    const profit = totalFreightCollect - totalCost

    // Cập nhật các giá trị vào `feesInfo`
    setValue('feesInfo.totalFeeCollected', formatNumberToFix(totalFreightCollect)) // Tổng thu
    setValue('feesInfo.totalCost', formatNumberToFix(totalCost)) // Tổng chi
    setValue('feesInfo.com', formatNumberToFix(totalCom)) // Com
    setValue('feesInfo.profit', formatNumberToFix(profit)) // Lợi nhuận
  }, [multipleValues, fields, setValue]) // Theo dõi thay đổi của các trường
}

/* eslint-enable @typescript-eslint/no-explicit-any */
