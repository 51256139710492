import { createSlice } from '@reduxjs/toolkit'
import { dataPreview } from '../../../types/order'

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    uuid: '',
    isFullForm: true,
    isNewForm: false,
    preview: false,
    dataSave: {} as dataPreview,
    dataPreview: {} as dataPreview
  },
  reducers: {
    setUuid: (state, action) => {
      state.uuid = action.payload
    },
    setIsFullForm: (state, action) => {
      state.isFullForm = action.payload
    },
    setIsNewForm: (state, action) => {
      state.isNewForm = action.payload
    },
    setDataSave: (state, action) => {
      state.dataSave = action.payload
    },
    setPreview: (state, action) => {
      state.preview = action.payload
    },
    setDataPreview: (state, action) => {
      state.dataPreview = action.payload
    }
  }
})

export const { setIsFullForm, setDataSave, setIsNewForm, setDataPreview, setPreview, setUuid } = orderSlice.actions

export default orderSlice.reducer
