import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { styled } from '@mui/material/styles'
import * as React from 'react'
import { COLORS } from '../../common/colors'
import { BootstrapDialogTitle } from './BootstrapDialogTitle'

const BootstrapDialog = styled(Dialog)<{ customstyles?: React.CSSProperties }>(({ theme, customstyles }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiDialog-paperFullWidth': {
    // Giữ nguyên các style mặc định nếu không truyền customstyles
    ...customstyles // Áp dụng các styles tùy chỉnh nếu có
  }
}))

interface CustomDialogProps extends Omit<DialogProps, 'title'> {
  title: string | React.ReactNode
  subTitle?: React.ReactNode
  children: React.ReactNode
  onSave?: () => void
  onClose: () => void
  saveButtonText?: string
  actions?: boolean
  classesTitle?: string
  id?: string
  customstyles?: React.CSSProperties
}

export const CustomDialog: React.FC<CustomDialogProps> = ({
  title,
  children,
  onClose,
  onSave,
  open,
  saveButtonText = 'Lưu',
  actions,
  subTitle,
  classesTitle,
  id,
  customstyles,
  ...dialogProps
}) => {
  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby={id ? id : 'customized-dialog-title'}
      open={open}
      customstyles={customstyles}
      {...dialogProps}
    >
      <BootstrapDialogTitle classes={classesTitle} id={id ? id : 'customized-dialog-title'} onClose={onClose}>
        <span>
          {title}
          {subTitle && (
            <Typography variant='h5' sx={{ color: COLORS.text, fontSize: '12px', fontWeight: '400' }}>
              {subTitle}
            </Typography>
          )}
        </span>
      </BootstrapDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      {actions && (
        <DialogActions>
          <Button autoFocus onClick={onSave}>
            {saveButtonText}
          </Button>
        </DialogActions>
      )}
    </BootstrapDialog>
  )
}
