import { RiFunctionLine, RiFunctionFill } from '@remixicon/react'
import { PERMISSION } from '../../constants'
import { createCollapseGroup, createItem } from '../../help'
import ROUTES from '../../routers/helpersRouter/constantRouter'
import { MenuItem } from '../../types'

const icons = {
  RiFunctionLine,
  RiFunctionFill
}

export const categoryMenuItem: MenuItem[] = [
  createCollapseGroup({
    id: 'list-category',
    title: 'Quản lý danh mục',
    icon: icons.RiFunctionLine,
    iconActive: icons.RiFunctionFill,
    premissions: [PERMISSION.ADMIN],
    children: [
      createItem({
        id: 'category-shell-type',
        title: 'Loại hình đóng gói',
        url: `/${ROUTES.CATEGORY}/${ROUTES.CATEGORY_CHILD.SHELL_TYPE}`,
        premissions: [PERMISSION.ADMIN]
      }),
      createItem({
        id: 'category-car-type',
        title: 'Phương tiện',
        url: `/${ROUTES.CATEGORY}/${ROUTES.CATEGORY_CHILD.CAR_TYPE}`,
        premissions: [PERMISSION.ADMIN]
      }),
      createItem({
        id: 'category-shipping-lines',
        title: 'Hãng tàu',
        url: `/${ROUTES.CATEGORY}/${ROUTES.CATEGORY_CHILD.SHIPPING_LINES}`,
        premissions: [PERMISSION.ADMIN]
      }),
      createItem({
        id: 'category-currency',
        title: 'Tiền tệ',
        url: `/${ROUTES.CATEGORY}/${ROUTES.CATEGORY_CHILD.CURRENCY}`,
        premissions: [PERMISSION.ADMIN]
      }),
      createItem({
        id: 'category-order-type',
        title: 'Loại hình đơn hàng',
        url: `/${ROUTES.CATEGORY}/${ROUTES.CATEGORY_CHILD.ORDER_TYPE}`,
        premissions: [PERMISSION.ADMIN]
      }),
      createItem({
        id: 'category-staff-type',
        title: 'Loại nhân viên',
        url: `/${ROUTES.CATEGORY}/${ROUTES.CATEGORY_CHILD.STAFF_TYPE}`,
        premissions: [PERMISSION.ADMIN]
      }),
      createItem({
        id: 'category-payment-method',
        title: 'Phương thức thanh toán',
        url: `/${ROUTES.CATEGORY}/${ROUTES.CATEGORY_CHILD.PAYMENT_METHOD}`,
        premissions: [PERMISSION.ADMIN]
      })
    ]
  })
]
